import React from 'react'
import { Link } from 'gatsby'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"

const NotFound = () => {
    return (
        <Layout>
            <Navbar />
            <div className="seo-agency-banner text-center">
            <h1>Opps... Something is missing.</h1>
            <Link to="/" className="default-btn"> Go Back Home</Link>
            </div>
            <Footer />
        </Layout>
    )
}

export default NotFound
